body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

/***********************************************************************************************************************
*
* Theming
*
***********************************************************************************************************************/

$exa-blue: #4F64A4;

$exa-dark: #4C5B87;
$exa-bright: #26C1ED;
$exa-orange: #EF9362;
$exa-red: #BA2E0B;
$exa-green: #77A33E;
$exa-purple: #6C56A3;
$exa-kaki: #A39C3E;
$exa-blue2: #468EA3;
$exa-brown: #A3805F;
$exa-rost: #A35F74;
$exa-green2: #46A350;

$exa-colors: (
        "dark":$exa-dark,
        "bright":$exa-bright,
        "orange":$exa-orange,
        "red":$exa-red,
        "green":$exa-green,
        "purple": $exa-purple,
        "kaki": $exa-kaki,
        "blue2": $exa-blue2,
        "brown": $exa-brown,
        "rost": $exa-rost,
        "green2": $exa-green2
);

$custom-colors: (
        "primary": $exa-blue,
        "info": $exa-orange,
);

$primary: #4F64A4;

$theme-colors: map-merge($theme-colors, $custom-colors);


$exa-blue-100: tint-color($exa-blue, 80%) !default;
$exa-blue-200: tint-color($exa-blue, 60%) !default;
$exa-blue-300: tint-color($exa-blue, 40%) !default;
$exa-blue-400: tint-color($exa-blue, 20%) !default;
$exa-blue-500: $exa-blue !default;
$exa-blue-600: shade-color($exa-blue, 20%) !default;
$exa-blue-700: shade-color($exa-blue, 40%) !default;
$exa-blue-800: shade-color($exa-blue, 60%) !default;
$exa-blue-900: shade-color($exa-blue, 80%) !default;

$exa-font-color: $exa-blue-800;

$headings-color: $exa-font-color !important;
$body-color: $exa-font-color;
$list-group-color: $exa-font-color !important;

$body-bg: #f6f6f6;


@each $color, $value in $exa-colors {
  .bg-exa-#{$color} {
    background-color: $value;
    color: white;
  }
}

/***********************************************************************************************************************
*
* Navigation
*
***********************************************************************************************************************/

$nav-pills-link-active-bg: #4F64A4;
$nav-link-color: #4F64A4;


/***********************************************************************************************************************
*
* Cards and Lists
*
***********************************************************************************************************************/

$card-border-width: 0;
$card-box-shadow: $box-shadow-sm;

.card {
  transition: all 300ms ease-in-out;
}

.card:hover {
  box-shadow: 0 .25rem .5rem rgba($black, .1);
}


$enable-shadows: true;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

/***********************************************************************************************************************
*
* Helpers
*
***********************************************************************************************************************/

.page-header {
  font-size: 3rem;
}

.cursor-pointer:hover {
  cursor: pointer;
}
